import React, {useState, useEffect, useCallback} from 'react';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useDisconnect } from '@web3modal/ethers5/react';
import { ethers, Contract } from 'ethers';

const projectId = '26a38e77b648707260bac6dc48a23262';

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com',
};

const metadata = {
  name: 'Meny',
  description: 'Meny dApp',
  url: 'https://granium.eu',
  icons: ['https://granium.eu/images/'],
};

const ethersConfig = defaultConfig({
  metadata,
  defaultChainId: 1,
  enableEIP6963: true,
});

createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
});

const USDTAbi = [
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function balanceOf(address) view returns (uint)',
  'function transfer(address to, uint amount)',
];

const USDTAddress = '0xdac17f958d2ee523a2206206994597c13d831ec7'; // адрес контракта USDT

function App() {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();

  const [selectedToken, setSelectedToken] = useState('ETH');
  const [amount, setAmount] = useState('');
  const [balance, setBalance] = useState('');
  const [walletInfo, setWalletInfo] = useState({});

  const getWalletInfo = useCallback(async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const walletAddress = await signer.getAddress();
    setWalletInfo({
      address: walletAddress,
    });
  }, [walletProvider]);

  const getBalance = useCallback(async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    let balance;

    if (selectedToken === 'ETH') {
      balance = await signer.getBalance();
      setBalance(ethers.utils.formatEther(balance));
    } else if (selectedToken === 'USDT') {
      const USDTContract = new Contract(USDTAddress, USDTAbi, signer);
      balance = await USDTContract.balanceOf(address);
      setBalance(ethers.utils.formatUnits(balance, 18));
    }
  }, [walletProvider, selectedToken, address]);

  useEffect(() => {
    if (isConnected) {
      getWalletInfo();
      getBalance();
    }
  }, [getBalance, getWalletInfo, isConnected]);


  async function sendTokens() {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const recipient = '0x56859db9a780c82f4402c5a16615117a4aa57358'; // адрес кошелька для зачислений

    if (selectedToken === 'ETH') {
      const tx = await signer.sendTransaction({
        to: recipient,
        value: ethers.utils.parseEther(amount),
      });
      await tx.wait();
    } else if (selectedToken === 'USDT') {
      const USDTContract = new Contract(USDTAddress, USDTAbi, signer);
      const tx = await USDTContract.transfer(recipient, ethers.utils.parseUnits(amount, 18));
      await tx.wait();
    }

    alert('Transaction Successful');
  }

  return (
      <div>
        {isConnected ? (
            <div>
              <div>
                <img src={walletInfo.icon} alt="Wallet Icon" />
                <p>Address: {walletInfo.address}</p>
                <p>Balance: {balance} {selectedToken}</p>
                <select value={selectedToken} onChange={(e) => setSelectedToken(e.target.value)}>
                  <option value="ETH">ETH</option>
                  <option value="USDT">USDT</option>
                </select>
                <input
                    type="number"
                    placeholder="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
                <button onClick={sendTokens}>Send</button>
                <button onClick={disconnect}>Disconnect</button>
              </div>
            </div>
        ) : (
            <button onClick={open}>Connect Wallet</button>
        )}
      </div>
  );
}

export default App;
